/* eslint-disable import/order */
import { decodedJwt } from '@/composables/cookies';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from '@/store/actions';
import { getters } from '@/store/getters';
import { account } from '@/store/modules/account/index';
import { analyticsreport } from '@/store/modules/analytics-report/index';
import { analytics } from '@/store/modules/analytics/analytics/index';
import { geography } from '@/store/modules/analytics/geography';
import { technology } from '@/store/modules/analytics/technology/index';
import { audience } from '@/store/modules/audience/index';
import { authentication } from '@/store/modules/authentication/index';
import { brandprotection } from '@/store/modules/brand-protection/index';
import { dashboard } from '@/store/modules/dashboard/index';
import { media } from '@/store/modules/media/index';
import { misc } from '@/store/modules/misc/index';
import { product } from '@/store/modules/products/index';
import { profile } from '@/store/modules/profile/index';
import { report } from '@/store/modules/reports/index';
import { user } from '@/store/modules/user/index';
import { mutations } from '@/store/mutations';
import { createStore, Store } from 'vuex';
import { campaign } from './modules/campaign/index';
import { company } from './modules/company/index';
import { email } from './modules/email';
import { integration } from './modules/integration/index';
import { link } from './modules/link';
import { lookup } from './modules/lookup/index';
import { metadata } from './modules/metadata';
import { notification } from './modules/notification';
import { signup } from './modules/signup';
import { techbatch } from './modules/tech-batch/index';
import { tech } from './modules/tech/index';
import { tracktrace } from './modules/tracktrace/index';
import { form } from './modules/form-builder';
import { shortUrl } from './modules/short-url';
import { page } from './modules/page';

const useDecodedJwt = decodedJwt();

export const state: IRootState = {
  auth: {
    isLoggedIn: !!useDecodedJwt,
    accessToken: null,
    refreshToken: null
  },
  authorized: false,
  drawer: false,
  set_drawer: false,
  show_loading: false,
  toasts: [],
  popup: false,
  popup_type: 'Scan',
  popup_name: '',
  popup_data: null,
  is_edit_mode: false,
  is_add_mode: false,
  is_view_mode: false,
  quick_popup: false,
  quick_popup_data: {},
  quick_popup_title: '',
  quick_popup_type: '',
  quick_popup_width: null,
  quick_popup_back: null,
  ability: null,
  status: '',
  error: '',
  friendly_error_message: '',
  get_date: {
    start: new Date(),
    end: new Date()
  },
  is_refreshed_token: false,
  is_first_load: true,
  refresh_retry_count: 0,
  is_ecocan_host: false
};
export const store: Store<IRootState> = createStore({
  modules: {
    dashboard,
    technology,
    audience,
    report,
    analyticsreport,
    geography,
    brandprotection,
    analytics,
    product,
    misc,
    media,
    authentication,
    account,
    user,
    profile,
    techbatch,
    lookup,
    company,
    campaign,
    tracktrace,
    tech,
    metadata,
    notification,
    form,
    email,
    signup,
    link,
    integration,
    shortUrl,
    page
  },
  state,
  getters,
  mutations,
  actions
});
